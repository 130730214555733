
// import lang from "@/mixins/lang.js";
export default {
  name: "Generic",
  props: {
    section: {
      type: Object,
      default() { },
    },
    imagePosition: {
      type: String,
      default: "",
    },
    dataid: {
      type: String,
      default: "",
    },
  },
};
