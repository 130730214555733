
import VueMarkdown from "vue-markdown";

export default {
  name: "Markdown",
  components: { VueMarkdown },
  props: {
    source: {
      type: String,
      default: "",
    },
  },
};
