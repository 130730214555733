
import lang from "@/mixins/lang.js";
export default {
  name: "Numbered",
  mixins: [lang],
  props: {
    section: {
      type: Object,
      default() {
        return [];
      },
    },
  },
  methods: {},
};
