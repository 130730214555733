
export default {
  name: "ButtonScrollDown",
  data() {
    return {
      scrollNum: 0,
    };
  },
  mounted() {
    window.addEventListener(
      "scroll",
      () => {
        let top =
          document.documentElement.scrollTop ||
          document.body.scrollTop ||
          window.pageYOffset;
        this.scrollNum = top;
      },
      { passive: true }
    );
  },
  methods: {
    goDown() {
      let height = window.innerHeight;
      document.documentElement.scrollTop = height;
    },
  },
};
