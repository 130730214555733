
import lang from "~/mixins/lang.js";
import device from "~/mixins/device.js";
import seo from "@/mixins/seo.js";
export default {
  layout: "default",
  mixins: [lang, seo, device],
  data() {
    return {
      pageData: {},
      seo: {},
    };
  },
  async fetch() {
    try {
      const { data } = await this.$axios.get(
        `home?_locale=${
          this.activeLang && this.activeLang.length > 0
            ? this.activeLang
            : this.browserLang && this.browserLang.length > 0
            ? this.browserLang
            : "en"
        }`
      );
      this.pageData = data;
      this.seo = data.SEO;
    } catch (ex) {}
  },
};
