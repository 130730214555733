import { render, staticRenderFns } from "./index.vue?vue&type=template&id=4ca8e34c"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SectionHeroRegular: require('/usr/app/components/section/HeroRegular.vue').default,ButtonScrollDown: require('/usr/app/components/button/ScrollDown.vue').default,SectionNumbered: require('/usr/app/components/section/Numbered.vue').default,SectionSliderCard: require('/usr/app/components/section/SliderCard.vue').default,Markdown: require('/usr/app/components/markdown/Markdown.vue').default,SectionGeneric: require('/usr/app/components/section/Generic.vue').default})
