export default {
  head() {
    if (this.seo && this.seo !== null && this.seo !== undefined) {
      return {
        title: !!this.seo.metaTitle ? this.seo.metaTitle : 'Efabrika',
        htmlAttrs: {
          lang: this.activeLang && this.activeLang.length > 0 ? this.activeLang : 'en'
        },
        meta: [{
          hid: 'description',
          name: 'description',
          content: !!this.seo.metaDescription ? this.seo.metaDescription : 'Efabrika',
        },
        {
          rel: 'canonical',
          content: this.seo.canonicalURL?.length > 0 ? this.seo.canonicalURL : process.env.NUXT_ENV_BASE_URL + (this.$route.path == '/' ? '' : this.$route.path),
        },
        // OG Metas
        {
          hid: 'og:title',
          property: 'og:title',
          content: !!this.seo.metaTitle ? this.seo.metaTitle : 'Efabrika',
        },
        {
          hid: 'og:description',
          property: 'og:description',
          content: !!this.seo.metaDescription ? this.seo.metaDescription : 'Efabrika',
        },
        {
          hid: 'og:type',
          property: 'og:type',
          content: 'website',
        },
        {
          hid: 'og:site_name',
          name: 'site_name',
          property: 'og:site_name',
          content: 'Efabrika',
        },
        {
          hid: 'og:author',
          name: 'author',
          property: 'og:author',
          content: 'efabrika',
        },
        {
          hid: 'og:locale',
          property: 'og:locale',
          content: 'tr_TR',
        },
        {
          hid: 'og:image',
          property: 'og:image',
          content: this.seo?.shareImage?.url ?? './efabrika_og-image.jpg',
        },
        {
          hid: 'og:url',
          property: 'og:url',
          content: this.seo.canonicalURL?.length > 0 ? this.seo.canonicalURL : process.env.NUXT_ENV_BASE_URL + (this.$route.path == '/' ? '' : this.$route.path),
        },
        {
          hid: 'og:keywords',
          name: 'keywords',
          property: 'og:keywords',
          content: !!this.seo.keywords ? this.seo.keywords : 'Efabrika' || 'Efabrika',
        },
        {
          hid: 'twitter:image',
          name: 'twitter:image',
          property: 'twitter:image',
          content: this.seo?.shareImage?.url ?? './efabrika_og-image.jpg',
        },
        {
          hid: 'twitter:site',
          name: 'site',
          property: 'twitter:site',
          content: '@efabrika',
        },
        {
          hid: 'twitter:card',
          name: 'twitter:card',
          property: 'twitter:card',
          content: 'summary_large_image',
        },
        {
          hid: 'twitter:creator',
          name: 'twitter:creator',
          property: 'twitter:creator',
          content: '@efabrika',
        },
        {
          hid: 'twitter:title',
          name: 'twitter:title',
          property: 'twitter:title',
          content: !!this.seo.metaTitle ? this.seo.metaTitle : 'Efabrika',
        },
        {
          hid: 'twitter:description',
          name: 'twitter:description',
          property: 'twitter:description',
          content: !!this.seo.metaDescription ? this.seo.metaDescription : 'Efabrika',
        },
        {
          hid: 'twitter:image:alt',
          name: 'twitter:image:alt',
          property: 'twitter:image:alt',
          content: !!this.seo.shareImage && !!this.seo.shareImage.alternativeText ? this.seo.shareImage.alternativeText : 'Efabrika Og Image',
        },
        ],
      };
    } else {
      return {
        htmlAttrs: {
          lang: this.activeLang && this.activeLang.length > 0 ? this.activeLang : 'en'
        },
        title: 'Efabrika'
      }
    }
  }
};