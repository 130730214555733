
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import lang from "@/mixins/lang.js";
export default {
  components: {
    VueSlickCarousel,
  },
  name: "SliderCard",
  mixins: [lang],
  props: {
    section: {
      type: Object,
      default() {},
    },
  },
  data() {
    return {
      slickSettings: {
        centerMode: false,
        centerPadding: "0",
        focusOnSelect: true,
        infinite: true,
        slidesToShow: 4,
        speed: 250,
        arrows: false,
        dots: false,
        autoplay: true,
        autoplaySpeed: 3000,
        responsive: [
          {
            breakpoint: 1199,
            settings: {
              slidesToShow: 2,
              centerPadding: "60",
            },
          },
        ],
      },
    };
  },
};
